<template>
  <CardFeedback :departmentId="depId" :doctorId="null" type="clinic" />
</template>

<script>
import CardFeedback from "components/card-feedback/CardFeedback";
import { mapGetters } from "vuex";
import * as names from "store/names";

export default {
  name: "DepartmentCardFeedback",
  components: { CardFeedback },
  data: () => ({}),
  computed: {
    ...mapGetters({
      depId: names.DEPARTMENT_ID_CARD,
    }),
  },
};
</script>

<style scoped></style>
